import { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import AddInvestorToAccount from './components/AddInvestor'
import SyncInvestorCompaniesPage from './components/SyncInvestorCompaniesPage'
import RemoveInvestorCompanies from './components/RemoveInvestorCompanies'
import AddAnotherUser from './components/AddAnotherUser'
import RemoveInvestors from './components/RemoveInvestors'

function UserManagementWrapper() {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const userManagementMenus = [
    {
      title: 'Add Investor/Fund',
      subtitle: 'Add Investor/Fund to your account',
      icon: 'bi bi-person-fill-add',
      isSvg: false,
    },
    {
      title: 'Add Another User',
      subtitle: 'Give access to another user',
      icon: '/media/icons/duotune/general/multi-user-add.svg',
      isSvg: true,
    },
    {
      title: 'Sync Investor',
      subtitle: 'Sync newly published companies',
      icon: '/media/icons/duotune/general/sync-investor.svg',
      isSvg: true,
    },
    {
      title: 'Remove Investor/Fund',
      subtitle: 'Remove Investor from your account',
      icon: 'bi bi-person-fill-slash',
      isSvg: false,
    },
    {
      title: 'Remove Companies',
      subtitle: 'Remove companies from Investor',
      icon: '/media/icons/duotune/general/table-delete.svg',
      isSvg: true,
    },
  ]
  return (
    <div>
      <div className='row mb-9 gx-4 mt-4'>
        <div className={`col-sm-12 col-md-3`}>
          <div
            className={`card d-flex justify-content-center justify-content-xl-start flex-row-auto h-600px`}
          >
            <div className={`card-body overflow-auto rounded p-6`}>
              <div className='menu-item'>
                {userManagementMenus &&
                  userManagementMenus.map(
                    (
                      menu: { title: string; subtitle: string; icon: string; isSvg: boolean },
                      index: number
                    ) => {
                      return (
                        <div
                          className={`d-flex flex-row cursor-pointer bg-hover-gray-light text-hover-primary rounded mb-4 ${
                            selectedIndex === index ? 'bg-gray-light text-primary' : ''
                          }`}
                          onClick={() => setSelectedIndex(index)}
                        >
                          <span
                            className='menu-link p-3 py-2'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <button
                              className='btn btn-sm btn-icon bg-secondary bg-hover-white me-3 menu-icon rounded-circle w-50px h-50px'
                              type='button'
                            >
                              {menu.isSvg ? '' : ''}
                              <i
                                className={`${menu.icon} ${
                                  selectedIndex === index ? 'text-primary' : ''
                                }`}
                                style={{ fontSize: '30px', width: '30px', height: '30px' }}
                              />
                              <KTSVG
                                className={`svg-icon-3x ${
                                  selectedIndex === index ? 'text-primary' : ''
                                }`}
                                path={menu.icon || ''}
                              />
                            </button>
                            <span className=''>
                              <p className='mb-0 fs-3 fw-bold'>{menu.title}</p>
                              <span className='text-muted fs-8 '>{menu.subtitle}</span>
                            </span>
                          </span>
                        </div>
                      )
                    }
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className={`col-sm-12 col-md-9`}>
          <div
            className={`card d-flex justify-content-center justify-content-xl-start flex-row-auto h-600px ms-md-5 mt-6 mt-md-0`}
          >
            {selectedIndex === 0 && <AddInvestorToAccount />}
            {selectedIndex === 1 && <AddAnotherUser />}
            {selectedIndex === 2 && <SyncInvestorCompaniesPage />}
            {selectedIndex === 3 && <RemoveInvestors />}
            {selectedIndex === 4 && <RemoveInvestorCompanies />}
          </div>
        </div>
      </div>
    </div>
  )
}
export default UserManagementWrapper
