import { AllTransactionsType, CompanyObject, Shareholder } from '../../../../generated/graphql'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useEffect, useState } from 'react'
import { SelectOptionType } from '../../../../utils/MultiSelect'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ShareCertificateAGTable } from './AgGridTableComponents/ShareCertificateAGTable'

type ShareHolderProps = {
  companyData: CompanyObject
  allTransaction: AllTransactionsType
}
export function ShareCertificateWrapper(props: ShareHolderProps) {
  const [selectedInvestor, setSelectedInvestor] = useState<SelectOptionType[]>()
  const [filteredShareholder, setFilteredShareholder] = useState<Shareholder[]>()
  const investorList: SelectOptionType[] = []

  props.allTransaction.investorData?.forEach((investor: any) => {
    if (!investorList.some((existingInvestor) => existingInvestor.value === investor.name)) {
      investorList.push({ label: investor.name, value: investor.name })
    }
  })

  useEffect(() => {
    if (selectedInvestor && selectedInvestor.length > 0) {
      const selectedNames = selectedInvestor.map((investor) => investor.value)
      const selectedShareholderData = props.companyData.shareholders?.filter((shareholder: any) =>
        selectedNames.includes(shareholder.name)
      )
      if (selectedShareholderData && selectedShareholderData.length > 0)
        setFilteredShareholder(selectedShareholderData as Shareholder[])
    } else {
      setFilteredShareholder([])
    }
  }, [selectedInvestor, props.companyData.shareholders])
  return (
    <>
      <div
        className='card p-9 h-600px overflow-auto'
        style={{
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
          border: '1',
          borderColor: '#F1F1F2',
        }}
      >
        <div className='col-12 col-md-4 mb-2 mb-md-0'>
          <h4 className='required'>Select Shareholder:</h4>
          <Select
            options={investorList}
            styles={selectCustomStyles}
            isMulti={true}
            name='investorName'
            placeholder='--Select--'
            onChange={(e: any) => {
              setSelectedInvestor(e)
            }}
          />
        </div>
        {(!selectedInvestor || selectedInvestor?.length === 0) && (
          <div className='text-center my-6 mt-20'>
            <img
              alt='no items'
              src={toAbsoluteUrl('/media/custom-images/emptyStarredCompanies.svg')}
              className='h-200px my-8 w-200 justify-content-center'
            />
            <h4>No Shareholder has selected</h4>
            <div className='fw-semibold fs-6'>
              Please select a Shareholder from the dropdown above to view the Share Certificate.
            </div>
          </div>
        )}
        {filteredShareholder && filteredShareholder?.length > 0 && (
          <ShareCertificateAGTable
            data={filteredShareholder || []}
            companyData={props.companyData}
          />
        )}
      </div>
    </>
  )
}
