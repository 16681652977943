import { useRef, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { DeleteConfirmPopup } from '../../../../utils/DeleteConfirmPopup'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import {
  RemoveInvestorFromAccountMutation,
  RemoveInvestorFromAccountMutationVariables,
  useAllInvestorsQuery,
  useGetAllInvestmentsQuery,
  useGetAllTransactionsQuery,
  useRemoveInvestorFromAccountMutation,
} from '../../../../generated/graphql'
import Toast, { ToastType } from '../../../../utils/toast'

type AccountInvestorsProps = {
  investors: any[]
  account: any
}
export function AccountInvestorsTable(props: AccountInvestorsProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedInvestor, setSelectedInvestor] = useState<any>()
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)

  const mutateRemoveInvestor = useRemoveInvestorFromAccountMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveInvestorFromAccountMutation,
        _variables: RemoveInvestorFromAccountMutationVariables,
        _context: unknown
      ) => {
        const invKey = {
          input: { accountName: props.account?.name, accountId: props.account?.id },
        }
        setLoading(false)
        queryClient.invalidateQueries(useAllInvestorsQuery.getKey(invKey))
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
        queryClient.invalidateQueries(useGetAllTransactionsQuery.getKey())
        Toast('Investor Removed Successfully', ToastType.success)
      },
      onError: (error: any) => {
        setLoading(false)
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleRemoveInvestor(e: React.MouseEvent, investorId: string) {
    e.stopPropagation()
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    e.preventDefault()
    mutateRemoveInvestor.mutate({ input: { id: selectedInvestor.id } })
  }

  const tableData = props.investors?.map((investor: any, index: number) => {
    return (
      <tr key={`${investor.name}`}>
        <td className='d-flex align-items-center'>
          {investor.companyId !== '' && (
            <i
              className={'bi bi-person-circle text-gray-600'}
              style={{ fontSize: '30px', width: '30px', height: '30px' }}
            />
          )}{' '}
          <p className='mb-0 fw-bold ms-2 fs-5'>{investor.name}</p>
        </td>
        <td className='pt-4 text-end'>
          <div>
            <i
              className={
                'bi bi-trash3 text-danger  bg-hover-light-danger p-2 px-3 rounded-circle cursor-pointer'
              }
              style={{ fontSize: '18px', width: '18px', height: '18px' }}
              onClick={() => {
                setSelectedInvestor(investor)
                setShowDeleteModal(true)
              }}
            />
          </div>
        </td>
      </tr>
    )
  })

  return (
    <>
      <div
        className='card border border-gray-300 p-3 px-6 h-400px mt-4'
        style={{
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
          border: '1',
          borderColor: '#F1F1F2',
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'none',
        }}
      >
        <table className='table table-row-bordered'>
          <thead>
            <tr>
              <th className='fw-bold'>Investor/Fund Name</th>
              <th className='fw-bold'></th>
            </tr>
          </thead>

          <tbody className='table-responsive'>{tableData}</tbody>
        </table>

        {showDeleteModal && (
          <DeleteConfirmPopup
            showModel={showDeleteModal}
            setShowModel={() => setShowDeleteModal(false)}
            loading={loading}
            currentValue={selectedInvestor.name}
            title='Remove Investor'
            confirmText={`Enter the Investor name "${selectedInvestor.name}" to continue`}
            description='This action will remove the Investor from the account.'
            primaryAction={(e: React.MouseEvent) => {
              handleRemoveInvestor(e, selectedInvestor)
              setShowDeleteModal(false)
            }}
            primaryButtonText='Remove'
          />
        )}
      </div>
    </>
  )
}
