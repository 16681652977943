import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'
import { isoToDate } from '../../../../../utils/FormatDate'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import ManageInvestmentTags from '../ManageInvestmentTags'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import RemoveTransactionModal from '../../../manual-investments/components/RemoveTransaction/RemoveTransactionModal'
import { AddIndirectTransactionModal } from '../../../manual-investments/components/AddAdditionalIndirectTransaction/AddIndirectTransactionModal'
import ManageTransactionDocumentModal from '../ManageTransactionDocument'
import { CompanyTypes } from '../../../../../generated/graphql'
import { TransactionNotes } from '../TransactionNotes'
import ManageTransactionMetadata from '../ManageTransactionMetadata'

export function SchemeNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold'> {data.data.schemeName}</p>
    </div>
  )
}

export function UnitTypeRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.unitType}</p>
    </div>
  )
}

export function NumOfUnitsRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.noOfUnits,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function DrawdownRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.drawdown,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function InvestmentAmountRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.investmentAmount,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function EstimatedFMVRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.estimatedFMV,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function DateOfInvestmentRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.dateOfInvestment ? isoToDate(data.data.dateOfInvestment) : '-'}</p>
    </div>
  )
}

export function TagsRender(data: any) {
  return (
    <div className='py-2'>
      {data.data.tags &&
        data.data.tags.map((tag: string, index: number) => {
          return (
            <span
              key={index}
              className={`badge fs-9 fw-semibold mb-1 me-2 `}
              style={{
                background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
              }}
            >
              {tag}
            </span>
          )
        })}
    </div>
  )
}

export function IndirectTransactionActionRender(data: any) {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [showNotesModel, setShowNotesModel] = useState(false)
  const [showMetadataModel, setShowMetadataModel] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data])

  const setShowModalFunction = (state: boolean) => setShowTagsModal(state)
  const manualOptions = [
    'Delete Transaction',
    'Edit Transaction',
    'Manage Tags',
    'Manage Documents',
    'Notes',
    'Manage Transaction',
  ]
  const actionMenu = () => {
    let newOptions = manualOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            if (option === 'Edit Transaction') {
              setShowEditModal(true)
            } else if (option === 'Delete Transaction') {
              setShowDeleteModal(true)
            } else if (option === 'Manage Tags') {
              setShowTagsModal(true)
            } else if (option === 'Manage Documents') {
              setShowDocumentModal(true)
            } else if (option === 'Notes') {
              setShowNotesModel(true)
            } else if (option === 'Manage Transaction') {
              setShowMetadataModel(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer  '>{option}</span>
        </label>
      )
    })
  }
  return (
    <div className='px-0'>
      {data.data.schemeName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}
      {showTagsModal && (
        <ManageInvestmentTags
          companyDetails={data.data.companyDetails}
          hissaCompanyId={data.data.companyDetails.hissaCompanyId}
          investorId={data.data.transactionDetails.investorId}
          tags={data.data.tags}
          showModal={showTagsModal}
          setShow={setShowModalFunction}
          investmentId={undefined}
          hissaRecordId={data.data.transactionDetails.hissTransactionId}
          indirectInvestmentId={data.data.transactionDetails.id}
          transactionDetails={data.data.transactionDetails}
          transactionCurrency={data.data.transactionDetails.transactionCurrency}
          transactionAmount={data.data.transactionDetails.transactionAmount}
          noOfUnits={data.data.transactionDetails.noOfUnits}
        />
      )}
      {showEditModal && (
        <AddIndirectTransactionModal
          showModal={showEditModal}
          handleClose={() => {
            setShowEditModal(false)
          }}
          isCompanyExist={true}
          companyName={data.data.companyDetails.name}
          cin={data.data.companyDetails.cin || ''}
          industry={data.data.companyDetails.industry || ''}
          companyCurrency={data.data.companyDetails.companyCurrency || ''}
          companyData={data.data.companyDetails}
          handleSubmit={() => setShowEditModal(false)}
          transactionData={data.data.transactionDetails}
          isEdit={true}
        />
      )}

      {showDeleteModal && (
        <RemoveTransactionModal
          showModal={showDeleteModal}
          handleClose={() => {
            setShowDeleteModal(false)
          }}
          investmentId={data.data.transactionDetails.id}
          investorId={data.data.transactionDetails.investorId}
          isLastInvestment={data.data.isLastInvestment}
          companyDetails={data.data.companyDetails}
          isIndirect={true}
        />
      )}
      {showDocumentModal && (
        <ManageTransactionDocumentModal
          show={showDocumentModal}
          handleClose={() => {
            setShowDocumentModal(false)
          }}
          hissaVerified={data.data.companyDetails?.type === CompanyTypes.Hissa ? true : false}
          folder={'Transaction'}
          isIndirect={true}
          transactionData={data.data.transactionDetails}
          companyData={data.data.companyDetails}
        />
      )}
      {showNotesModel && (
        <TransactionNotes
          handleClose={() => setShowNotesModel(false)}
          notes={data.data.notes}
          showModel={showNotesModel}
          companyDetails={data.data.companyDetails}
          hissaCompanyId={data.data.companyDetails.hissaCompanyId}
          investorId={data.data.transactionDetails.investorId}
          tags={data.data.tags}
          showModal={showTagsModal}
          setShow={setShowModalFunction}
          investmentId={undefined}
          hissaRecordId={data.data.transactionDetails.hissTransactionId}
          indirectInvestmentId={data.data.transactionDetails.id}
          transactionDetails={data.data.transactionDetails}
          transactionCurrency={data.data.transactionDetails.transactionCurrency}
          transactionAmount={data.data.transactionDetails.transactionAmount}
          noOfUnits={data.data.transactionDetails.noOfUnits}
        />
      )}
      {showMetadataModel && (
        <ManageTransactionMetadata
          companyDetails={data.data.companyDetails}
          hissaCompanyId={data.data.companyDetails.hissaCompanyId}
          investorId={data.data.transactionDetails.investorId}
          tags={data.data.tags}
          showModal={showMetadataModel}
          setShow={setShowMetadataModel}
          investmentId={undefined}
          hissaRecordId={data.data.transactionDetails.hissTransactionId}
          indirectInvestmentId={data.data.transactionDetails.id}
          transactionDetails={data.data.transactionDetails}
          transactionCurrency={data.data.transactionDetails.transactionCurrency}
          transactionAmount={data.data.transactionDetails.transactionAmount}
          noOfUnits={data.data.transactionDetails.noOfUnits}
        />
      )}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
