import { useEffect, useRef, useState } from 'react'
import { useGetCaptableIframeToken } from '../../../../modules/auth/core/_requests'
import { auto } from '@popperjs/core'

type Props = {
  hissaCompanyId: string
  type: ViewType
  investorNames?: string[]
}

export enum ViewType {
  CAPTABLE = 'CAPTABLE',
  RIGHTS = 'RIGHTS',
  SECURITIES = 'SECURITIES',
  TRANSACTIONS = 'TRANSACTIONS',
  FINANCIALS = 'FINANCIALS',
  BOARDMODULE = 'BOARDMODULE',
}

function GenericIframeView(props: Props) {
  const { data, isFetched } = useGetCaptableIframeToken(props.hissaCompanyId)
  const [token, setToken] = useState('')
  useEffect(() => {
    if (data && data.data) setToken(data.data)
  }, [data])

  let view = props.type.toLowerCase() as string

  if (props.type === ViewType.TRANSACTIONS) {
    view = 'transaction/allTransactions'
  } else if (props.type === ViewType.RIGHTS) {
    view = 'rights/overview'
  } else if (props.type === ViewType.SECURITIES) {
    view = 'security'
  } else if (props.type === ViewType.FINANCIALS) {
    view = 'finance'
  } else if (props.type === ViewType.BOARDMODULE) {
    view = 'setting/boardModule'
  }

  if (!isFetched) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  const iframeUrl =
    props.type === ViewType.BOARDMODULE
      ? `${process.env.REACT_APP_CAPTABLE_CLIENT_URL}/inv/${view}/${
          props.hissaCompanyId
        }/${token}?investors=${JSON.stringify(props.investorNames)}`
      : `${process.env.REACT_APP_CAPTABLE_CLIENT_URL}/inv/${view}/${props.hissaCompanyId}/${token}`

  return token && token.length > 0 ? (
    <div className='row m-0'>
      <div className='card'>
        <div className='card-body border-0 p-0' style={{ scrollbarWidth: 'none' }}>
          <div className='row' style={{ overflow: 'hidden', height: 'calc(100vh - 110px - 45px)' }}>
            <iframe
              src={iframeUrl}
              title='CapTable'
              loading='eager'
              width='100%'
              style={{ border: 'none', height: '100%', padding: 0, overflow: auto }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default GenericIframeView
