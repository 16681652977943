import {
  AllAccountOfUserQuery,
  AllInvestorsQuery,
  GetExistingHissaCompaniesQuery,
  GetNewHissaCompaniesQuery,
  useAllAccountOfUserQuery,
  useAllInvestorsQuery,
  useGetExistingHissaCompaniesQuery,
  useGetNewHissaCompaniesQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useEffect, useState } from 'react'
import { getUserInvestors } from '../../../../utils/LocalStorageUtils'
import { InvestorNewCompaniesTable, newCompanyData } from './InvestorCompaniesAGTable'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { account } from './AddAnotherUser'
import { AccountInvestorsTable } from './RemoveInvestorsTable'

function RemoveInvestors() {
  const [selectedAccount, setSelectedAccount] = useState<account>()

  useEffect(() => {
    if (selectedAccount !== undefined) {
      const getExistingCompaniesQueryKey = useGetExistingHissaCompaniesQuery.getKey()
      queryClient.invalidateQueries(getExistingCompaniesQueryKey)
    }
  }, [selectedAccount])

  const { data, isLoading } = useAllAccountOfUserQuery<AllAccountOfUserQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const accountOptions = data?.getAllAccountOfUser?.map((account: any, index: number) => {
    return { label: account.name, value: account.id, index: index }
  })

  const { isSuccess, data: allInvestors } = useAllInvestorsQuery<AllInvestorsQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        accountName: selectedAccount?.name,
        accountId: selectedAccount?.id,
      },
    },
    { enabled: selectedAccount !== undefined, cacheTime: 0, staleTime: 0 }
  )

  return (
    <>
      <div className='col p-9'>
        <div className='card'>
          <h2 className='card-title fw-bold'>Remove Investor/Fund</h2>
          <div className='separator' />
          <div className='col-12 col-md-6'>
            <div className='pb-5 pt-5'>
              <h4 className='required'>Select Account :</h4>
              <Select
                options={accountOptions}
                styles={selectCustomStyles}
                name='accountName'
                onChange={(e: any) => {
                  setSelectedAccount(e.value)
                }}
              />
            </div>
          </div>
        </div>
        {allInvestors?.getAllInvestors &&
        allInvestors?.getAllInvestors?.length > 0 &&
        selectedAccount !== undefined ? (
          <AccountInvestorsTable
            investors={allInvestors?.getAllInvestors || []}
            account={selectedAccount}
          />
        ) : (
          <>
            <div
              className='card p-9 my-9 h-375px'
              style={{
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                border: '1',
                borderColor: '#F1F1F2',
              }}
            >
              {!isLoading && (
                <div className='text-center mt-6'>
                  {allInvestors?.getAllInvestors?.length === 0 ? (
                    <>
                      <img
                        alt='no items'
                        src={toAbsoluteUrl('/media/custom-images/emptyRecentCompanies.svg')}
                        className='h-150px my-6 w-200 justify-content-center'
                      />
                      <h4>No Investor were found in the selected Account.</h4>
                    </>
                  ) : (
                    <>
                      <img
                        alt='no items'
                        src={toAbsoluteUrl('/media/custom-images/emptyStarredCompanies.svg')}
                        className='h-150px my-6 w-200 justify-content-center'
                      />
                      <h4>No Account has selected</h4>
                      <div className='fw-semibold fs-6'>
                        Please select Account above to view new Investors/Fund
                      </div>
                    </>
                  )}
                </div>
              )}
              {isLoading && (
                <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
                  <span className='spinner-grow'></span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default RemoveInvestors
