import clsx from 'clsx'
import { KTSVG } from '../../../helpers'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const SwitchProduct = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'svg-icon-2',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const isTaskManagerExists = true
  const isCaptableExists = true

  const HandleTaskManager = () => {
    window.open(process.env.REACT_APP_TASKMANAGER_CLIENT_URL)
  }

  const HandleCaptable = () => {
    window.open(process.env.REACT_APP_CAPTABLE_CLIENT_URL)
  }

  const products = [
    {
      name: 'Task Manager',
      enabled: isTaskManagerExists,
      current: false,
      description: 'Manage Project & Tasks',
      onClick: () => HandleTaskManager(),
      icon: (
        <div className='bg-gray-200 bg-hover-white rounded p-2'>
          <KTSVG
            path='/media/icons/duotune/files/fil008.svg'
            className='svg-icon-2x text-primary cursor-pointer'
          />
        </div>
      ),
    },
    ,
    {
      name: 'Cap Table',
      enabled: isCaptableExists,
      current: false,
      description: 'Equity Ownership of Company',
      onClick: () => {
        HandleCaptable()
      },
      icon: (
        <div className='bg-gray-200 bg-hover-white rounded p-2'>
          <KTSVG
            path='/media/icons/duotune/graphs/gra007.svg'
            className='svg-icon-2x text-primary cursor-pointer'
          />
        </div>
      ),
    },
    // {
    //   name: "ESOP",
    //   enabled: isEsopExists,
    //   current: false,
    //   description: "ESOP Management of Company",
    //   onClick: () => {},
    //   icon: (
    //     <div>
    //       <img
    //         className="h-8"
    //         src={isEsopExists ? "/images/esop.svg" : "/images/esop-grayed.svg"}
    //         alt="esop"
    //         color="#E85936"
    //       />
    //     </div>
    //   ),
    // },
  ]

  return (
    <>
      <a
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <KTSVG
          path='/media/icons/duotune/abstract/abs030.svg'
          className='svg-icon-1 text-gray-700 cursor-pointer text-hover-primary'
        />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-800 menu-icon-muted menu-state-primary fw-semibold w-325px'
        data-kt-menu='true'
      >
        <div className='bg-gray-100 text-gray-900 text-center p-3'>Switch Product</div>

        <div className='p-4'>
          {products.map((item: any) => {
            return (
              <div className='menu-item mb-2 bg-hover-light rounded'>
                <div className='menu-link px-4 py-2 gap-4' onClick={() => item.onClick()}>
                  <span className='menu-icon rounded' data-kt-element='icon'>
                    {/* <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-3' /> */}
                    {item.icon}
                  </span>
                  <span className='menu-title d-flex flex-column align-items-start'>
                    <p className='fw-bold fs-5 mb-0 fw-semibold'>{item.name}</p>
                    <div className='fs-7 text-gray-500'>{item.description}</div>
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export { SwitchProduct }
