import { useState } from 'react'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'

export function getSecurityColor(securityType: string): string {
  switch (securityType?.toUpperCase()) {
    case 'EQUITY':
      return 'custom-security equity'
    case 'PREFERENCE':
      return 'custom-security preference'
    case 'CCD':
      return 'custom-security ccd'
    case 'OCD':
      return 'custom-security ocd'
    case 'NOTE':
      return 'custom-security note'
    case 'WARRANT':
      return 'custom-security warrant'
    case 'RPS':
      return 'custom-security rps'
    case 'NCD':
      return 'custom-security ncd'
    case 'OCPS':
      return 'custom-security ocps'
    case 'OCRPS':
      return 'custom-security ocrps'
    case 'DEBENTURE':
      return 'custom-security debenture'
    case 'OPTIONS':
      return 'custom-security options'
    case 'CCPS':
      return 'custom-security ccps'
    case 'FORFEITED':
      return 'custom-security forfeited'
    default:
      return 'bg-light text-success'
  }
}

export function SecurityClassRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold mb-0'>{data.data.securityClass}</p>
      {data.data.demat && (
        <p className='px-2 border mb-2 fs-8 border-success bg-light-success text-success  d-inline-block'>
          Demat
        </p>
      )}
    </div>
  )
}

export function SecurityTypeRender(data: any) {
  return (
    <div
      className={`${getSecurityColor(
        data.data.securityType
      )} mt-2 fs-7 py-1 text-center align-middle fw-semibold`}
    >
      <p className='mb-0'>{data.data.securityType}</p>
    </div>
  )
}

export function RoundsRender(data: any) {
  return (
    <div className='text-start'>
      {Object.keys(data.data.rounds).length > 0 && (
        <div
          className='pt-2 cursor-pointer'
          data-bs-toggle='tooltip'
          title={data.data.rounds.roundIdentifier + ' - ' + data.data.rounds.roundName}
        >
          <p className='border mb-0 border-gray-600 text-gray-600 rounded-1 d-inline-block px-2'>
            {data.data.rounds.roundIdentifier}
          </p>
        </div>
      )}
      {Object.keys(data.data.rounds).length === 0 && <div className='pt-2 mx-2'>-</div>}
    </div>
  )
}

export function ShareHolderRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.shareHolder}</p>
    </div>
  )
}

export function ShareNumbersRender(data: any) {
  const [viewMore, setViewMore] = useState<boolean>(false)

  const formatRange = (numbers: number[]): string => {
    if (!numbers || numbers.length === 0) return ''
    if (numbers.length === 1) return `${numbers[0]}`
    return `${numbers[0]} - ${numbers[numbers.length - 1]}`
  }

  return (
    <td className='pt-2 text-nowrap align-middle'>
      <div
        className={`${viewMore && data.data?.shareNumbers?.length > 4 ? 'h-20' : ''} overflow-auto`}
      >
        {data.data?.shareNumbers?.map((share: number[], index: number) =>
          viewMore ? (
            <p key={index} className='mb-1 fw-semibold'>
              {formatRange(share)}
            </p>
          ) : (
            index < 3 && (
              <p key={index} className='mb-1 fw-semibold'>
                {formatRange(share)}
              </p>
            )
          )
        )}

        {data.data?.shareNumbers?.length > 3 && (
          <p className='text-primary cursor-pointer fw-bold' onClick={() => setViewMore(!viewMore)}>
            {viewMore ? 'View Less' : 'View More'}
          </p>
        )}
      </div>
    </td>
  )
}

export function NumOfSecuritiesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.numOfSecurities,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PPSRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.pps,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function AmountInvestedRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.amountInvested,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function CurrentValueRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.currentValue,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
