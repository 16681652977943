/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { FC, useContext, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'
import { getAuth, JwtPayload } from '../../../../app/modules/auth'
import jwtDecode from 'jwt-decode'
import Select from 'react-select'
import { SelectOptionType } from '../../../../utils/MultiSelect'
import { currencies } from '../../../../utils/CurrencyList'
import { selectCustomStyles } from '../../../../utils/Select'
import { GlobalContext, GlobalContextType } from '../../../../app/pages/store/context/globalContext'
import { Actions } from '../../../../app/pages/store/context/reducer'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import {
  AddUserCurrencyMutation,
  AddUserCurrencyMutationVariables,
  GetUserCurrencyQuery,
  useAddUserCurrencyMutation,
  useGetAllTransactionsQuery,
  useGetCompanyWiseDataQuery,
  useGetUserCurrencyQuery,
} from '../../../../generated/graphql'
import Toast, { ToastType } from '../../../../utils/toast'
import { getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { useUserNameStore } from '../../../../store/UserNameStore'
import { SwitchProduct } from './switchProduct'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const userNameStore = useUserNameStore()
  const { state, dispatch } = useContext<GlobalContextType>(GlobalContext)
  const { userCurrency } = state
  const [currencyDropDown, setCurrencyDropdown] = useState<boolean>(false)
  const [currencySymbol, setCurrencySymbol] = useState<string>(
    userCurrency !== '' ? getSymbolOfCurrency(userCurrency) : '₹'
  )
  if (userNameStore.userName === '') userNameStore.setUserName(jwt.fullName!)
  const [currency, setCurrency] = useState<SelectOptionType>()
  const allTransactionsQueryKey = useGetAllTransactionsQuery.getKey()
  const companyPageQueryKey = useGetCompanyWiseDataQuery.getKey()

  const currencyList: SelectOptionType[] = []
  currencies.forEach((currency: string) => {
    currencyList.push({
      value: currency,
      label: currency,
    })
  })

  const selectedCurrency = useGetUserCurrencyQuery<GetUserCurrencyQuery, Error>(
    graphqlRequestClient,
    {},
    { enabled: userCurrency === '' }
  )

  const mutateAddUserCurrency = useAddUserCurrencyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddUserCurrencyMutation,
        _variables: AddUserCurrencyMutationVariables,
        _context: unknown
      ) => {
        Toast('Updated User Currency Successfully', ToastType.success)
        queryClient.invalidateQueries(allTransactionsQueryKey)
        queryClient.invalidateQueries(companyPageQueryKey) // not working TODO: Fix this
        window.location.reload()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  if (selectedCurrency.isSuccess && !currency) {
    setCurrency({
      value: selectedCurrency.data?.getUserCurrency || 'INR - ₹',
      label: selectedCurrency.data?.getUserCurrency || 'INR - ₹',
    })
    dispatch({
      type: Actions.setUserCurrency,
      payload: selectedCurrency.data?.getUserCurrency || 'INR - ₹',
    })
  }
  useEffect(() => {
    if (userCurrency === undefined) {
      setCurrency({ value: 'INR - ₹', label: 'INR - ₹' })
    }

    if (userCurrency !== '' && currencyList?.length > 0) {
      const selectedObject = currencyList?.find((element) => element.value === userCurrency)
      if (selectedObject) {
        setCurrency(selectedObject)
        setCurrencySymbol(selectedObject.label!.split('-')[1]?.trim())
      }
    }
  }, [userCurrency])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
        />
      </div>
      <div className='d-flex align-items-center'>
        <SwitchProduct />
      </div>

      {/* end::Theme mode */}

      {/* begin::AccountSetting */}
      <div
        className={clsx(
          'd-flex align-items-center pe-4 cursor-pointer symbol',
          toolbarButtonMarginClass
        )}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        {!currencyDropDown && (
          <button
            type='button'
            className='btn btn-secondary btn-sm text-black rounded fs-4 text-hover-primary '
            onClick={() => setCurrencyDropdown(true)}
          >
            <span style={{ fontFamily: 'Arial' }}>{currencySymbol}</span>
          </button>
        )}
        {currencyDropDown && (
          <Select
            className='px-4'
            options={currencyList.filter((value) => Object.keys(value).length !== 0)}
            value={currency}
            onChange={(e: any) => {
              setCurrency(e)
              setCurrencyDropdown(false)
              setCurrencySymbol(e.value.split('-')[1]?.trim())
              dispatch({ type: Actions.setUserCurrency, payload: e.value })
              if (e.value) {
                mutateAddUserCurrency.mutate({
                  input: {
                    userCurrency: e.value,
                  },
                })
              }
            }}
            styles={selectCustomStyles}
          />
        )}
      </div>
      {/* <UserSettingMenu /> */}
      {/* end::AccountSetting */}

      {/* begin::User */}
      <div className='d-flex align-items-center text-dark fw-bold m-1 fs-6'>
        <div className='d-flex flex-column'>
          <span className='text-gray-heading fs-6 fw-semibold'>Hello</span>
          <span className='text-gray-heading fs-6 fw-bold'>{jwt.fullName}</span>
        </div>
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' />
        </div>
        <HeaderUserMenu userName={jwt.fullName!} userEmail={jwt.emailId!} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export { Topbar }
