import { OverlayTrigger, Popover } from 'react-bootstrap'
import { getCSSVariableValue } from '../_metronic/assets/ts/_utils'
import { hexToRGB } from './HexToRgba'
import { SpocDetailsType } from '../generated/graphql'

export function ShowTags(tags: string[]) {
  return (
    <>
      {tags &&
        tags.map((tag: string, index: number) => {
          return (
            <>
              <span
                key={index}
                className={`badge fs-9 fw-semibold mb-1 ms-2 `}
                style={{
                  background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
                  color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
                }}
              >
                {index < 2 && tag}
              </span>
              {index >= 2 && index === tags.length - 1 && (
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement='bottom'
                  rootCloseEvent='mousedown'
                  overlay={tagsPopover(tags)}
                >
                  <span
                    className={`badge fs-9 fw-semibold mb-1 ms-2 `}
                    style={{
                      background: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 0.2),
                      color: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 1),
                      cursor: 'pointer',
                    }}
                  >
                    {'+' + (tags.length - 2)}
                  </span>
                </OverlayTrigger>
              )}
            </>
          )
        })}
    </>
  )
}

const tagsPopover = (tags: string[]) => (
  <Popover id='popover-basic'>
    <Popover.Body>
      {tags.map((tag: string, index: number) => {
        return (
          <span
            className={`badge fs-9 fw-semibold mb-1 ms-2 text-wrap`}
            style={{
              background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
              color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
            }}
          >
            {index >= 2 && tag}
          </span>
        )
      })}
    </Popover.Body>
  </Popover>
)
const kmpPopover = (data: SpocDetailsType) => (
  <Popover id='popover-basic '>
    <Popover.Body className='p-2 text-wrap'>
      {data.name ? (
        <>
          <p className='fs-8 fw-semibold mb-1 ms-2 text-wrap text-gray-800'>{`Name : ${data.name}`}</p>
          {data.email && (
            <p className='fs-8 fw-semibold mb-1 ms-2 text-wrap text-gray-800'>{`Email : ${data.email}`}</p>
          )}
          {data.phone && (
            <p className='fs-8 fw-semibold mb-1 ms-2 text-wrap text-gray-800'>{`Phone : ${data.phone}`}</p>
          )}
        </>
      ) : (
        <></>
      )}
    </Popover.Body>
  </Popover>
)

export function ShowKmp({ data }: { data: SpocDetailsType[] }) {
  return (
    <>
      {data &&
        data.map((person: SpocDetailsType, index: number) => {
          return (
            <>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement='bottom'
                rootCloseEvent='mousedown'
                overlay={kmpPopover(person)}
              >
                <span className='me-1 cursor-pointer'>
                  {person.name}
                  {index !== data.length - 1 && ', '}
                </span>
              </OverlayTrigger>
            </>
          )
        })}
    </>
  )
}
